import { getDefaultConfig } from 'connectkit';
import { base } from 'viem/chains';
import { createConfig, http } from 'wagmi';

export const connectKitConfig = getDefaultConfig({
  chains: [base],
  transports: {
    [base.id]: http(
      `https://base-mainnet.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_API_KEY}`,
    ),
  },
  walletConnectProjectId: process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID as string,
  appName: 'Teleport',
});

export const wagmiConfig = createConfig(connectKitConfig);

export const connectKitOptions = {
  overlayBlur: 10,
  embedGoogleFonts: true,
  truncateLongENSAddress: true,
  reducedMotion: false,
  disclaimer: null,
  bufferPolyfill: true,
  walletConnectCTA: 'link',
} as const;

export const connectKitTheme = {
  '--ck-overlay-backdrop-filter': 'blur(16px)',
} as const;
