import type { SIWEConfig } from 'connectkit';
import { getCsrfToken, getSession, signIn, signOut } from 'next-auth/react';
import { SiweMessage } from 'siwe';

export const siweConfig: SIWEConfig = {
  getNonce: async () => {
    const csrfToken = await getCsrfToken();
    if (!csrfToken) throw new Error('Failed to get CSRF token');
    return csrfToken;
  },
  createMessage: ({ nonce, address, chainId }) =>
    new SiweMessage({
      nonce,
      chainId,
      address,
      version: '1',
      uri: window.location.origin,
      domain: window.location.host,
      statement: 'Sign In to Teleport to access your account.',
    }).prepareMessage(),
  verifyMessage: async ({ message, signature }) => {
    try {
      await signIn('siwe', {
        message,
        signature,
        redirect: true,
        callbackUrl: '/console/portfolio',
      });
      return true;
    } catch (error) {
      console.error('SIWE verification error:', error);
      return false;
    }
  },
  getSession: async () => {
    const session = await getSession();
    if (!session?.user) return null;
    return {
      address: session.user.address,
      chainId: session.user.chainId,
    };
  },
  signOut: async () => {
    await signOut({ redirect: true, callbackUrl: '/' });
    return true;
  },
};
