import { defaultShouldDehydrateQuery, QueryClient } from '@tanstack/react-query';
import type { Query } from '@tanstack/react-query';
import superjson from 'superjson';

// Create a single configuration object for consistency
const queryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 30 * 1000,
    },
    dehydrate: {
      serializeData: superjson.serialize,
      shouldDehydrateQuery: (query: Query) =>
        defaultShouldDehydrateQuery(query) || query.state.status === 'pending',
    },
    hydrate: {
      deserializeData: superjson.deserialize,
    },
  },
};

// Create a singleton instance for the client side
const clientSideQueryClient =
  typeof window !== 'undefined' ? new QueryClient(queryClientConfig) : null;

// Single exported function to get the query client
export function getQueryClient() {
  if (typeof window !== 'undefined') {
    return clientSideQueryClient ?? new QueryClient(queryClientConfig);
  }
  return new QueryClient(queryClientConfig);
}
