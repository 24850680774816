'use client';

import { useState } from 'react';
import { createTRPCClient, httpBatchLink, loggerLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import { type inferRouterInputs, type inferRouterOutputs } from '@trpc/server';
import superjson from 'superjson';
import { type AppRouter } from '@/server/api/routers/root';
import { getQueryClient } from './query-client';

export const trpc = createTRPCReact<AppRouter>();

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = RouterInputs['example']['hello']
 */
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = RouterOutputs['example']['hello']
 */
export type RouterOutputs = inferRouterOutputs<AppRouter>;

const getBaseUrl = () => {
  // if it is running on the browser, use the window.location.origin
  if (typeof window !== 'undefined') return '';
  // if it is running on vercel, use the automatically assigned vercel url
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`;
  // otherwise, use the local url
  return 'http://localhost:3000';
};

const clientConfig = {
  links: [
    loggerLink({
      enabled: (op) =>
        process.env.NODE_ENV === 'development' ||
        (op.direction === 'down' && op.result instanceof Error),
    }),
    httpBatchLink({
      transformer: superjson,
      url: `${getBaseUrl()}/api/trpc`,
    }),
  ],
};

export const trpcClient = createTRPCClient<AppRouter>(clientConfig);

export function TRPCReactProvider({ children }: { children: React.ReactNode }) {
  const queryClient = getQueryClient();

  const [trpcClient] = useState(() => trpc.createClient(clientConfig));

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      {children}
    </trpc.Provider>
  );
}
