import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getURL = () => {
  // Client
  if (typeof window !== 'undefined') {
    return new URL('/', window.location.origin);
  }

  // Fallback for SSG, you may use a custom env instead (Solution no. 2)
  if (process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'preview') {
    return new URL('https://staging.teleport.best');
  }
  if (process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'main') {
    return new URL('https://teleport.best');
  }
  if (process.env.NEXT_PUBLIC_VERCEL_URL) {
    return new URL(`https://${process.env.VERCEL_PROJECT_PRODUCTION_URL}`);
  }
  return new URL(`http://localhost:8000`);
};
