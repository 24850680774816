'use client';

import { ConnectKitButton } from 'connectkit';
import { Button } from '@/app/components/ui/button';

interface WalletButtonProps {
  variant?: 'default' | 'custom';
  className?: string;
}

export default function WalletButtons({ variant = 'default', className }: WalletButtonProps) {
  if (variant === 'custom') {
    return (
      <ConnectKitButton.Custom>
        {({ show }) => (
          <Button onClick={show} variant="filled" className={className}>
            Enter
          </Button>
        )}
      </ConnectKitButton.Custom>
    );
  }

  return <ConnectKitButton />;
}
