'use client';

import { QueryClientProvider } from '@tanstack/react-query';
import { ConnectKitProvider, SIWEProvider } from 'connectkit';
import type { State } from 'wagmi';
import { WagmiProvider } from 'wagmi';
import { Toaster } from '@/app/components/ui/sonner';
import { siweConfig } from '@/lib/auth/config';
import { TRPCReactProvider } from '@/lib/trpc/client';
import { getQueryClient } from '@/lib/trpc/query-client';
import { wagmiConfig, connectKitOptions, connectKitTheme } from '@/lib/wallet/config';

type ClientProvidersProps = {
  initialState?: State | undefined;
  children: React.ReactNode;
};

export function ClientProviders({ initialState, children }: ClientProvidersProps) {
  const handleDisconnect = async () => {
    await fetch(`/siwe`, { method: 'DELETE' });
  };

  return (
    <QueryClientProvider client={getQueryClient()}>
      <WagmiProvider config={wagmiConfig} initialState={initialState}>
        <TRPCReactProvider>
          <SIWEProvider {...siweConfig}>
            <ConnectKitProvider
              onDisconnect={void handleDisconnect}
              options={connectKitOptions}
              customTheme={connectKitTheme}
            >
              {children}
              <Toaster />
            </ConnectKitProvider>
          </SIWEProvider>
        </TRPCReactProvider>
      </WagmiProvider>
    </QueryClientProvider>
  );
}
